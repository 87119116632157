@import 'loaders.css/src/animations/line-scale.scss';

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
  transform: scale(1.5);
}

#overlay {
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
}

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
}