body {
    overflow-x: auto !important;
}
.legend {
    background-color: #fff;
    border-radius: 3px;
    bottom: 30px;
    color: black;
    box-shadow: 0 1px 2px rgba(0,0,0,0.10);
    font: 12px/20px;
    padding: 10px;
    position: absolute;
    right: 10px;
    z-index: 1;
    margin-right: 24px;
}
.legend h4 {
    margin: 0 0 10px;
}
.legend div span {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}
/* #mdiaMngrGrid:hover + .viewTxtBtn {
    display: block;
} */
#mdiaMngrGrid {
    position: relative;
}
.viewTxtBtn {
    /* display: none; */
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px !important;
    font-weight: 700 !important;
    background-color: #333;
    color: #fff;
    padding: 6px;
    border-radius: 2px;
    z-index: 1;
    box-shadow: 0px 0px 23px -12px rgba(0,0,0,0.55);
    cursor: pointer;
}
.viewTxtBtn:hoer {
    opacity: 0.8;
    box-shadow: 0px 0px 30px -12px rgba(0,0,0,0.75);
}
.videoSize iframe {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.794);
}

.galleryVideoSize {
    height: 90px;
}

.galleryVideoSize iframe {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.794);
    height: 100%;
}

.galleryVideoSize1 iframe {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.794);
    height: 100%;
}

.galleryVideoSize1 video {
    width: 100%;
    height: 90px;
    background-color: rgba(0, 0, 0, 0.794);
}

.detailImageSize {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: scale-down;
}
.imgTitleCapMain {
    position: relative;
    width: 100%;
    height: 100%;
}
.imgTitleCapDiv {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.titleCapImg {
    opacity: 1;
}
.imgTitleCapMain:hover .titleCapImg {
    opacity: 0.6;
}
.imgTitleCapMain:hover .imgTitleCapDiv {
    opacity: 1;
}
.imgCapTxt {
    color: white;
    font-size: 12px;
}
.imgTitleTxt {
    background-color: #1f1f1fb7;
    padding: 12px 14px;
    color: white;
    font-size: 16px;
    width: 100%;
    height: 100%;
}
.h1Cls {
    background-color: #6d6d6d !important
}
.h2Cls {
    background-color: #b1b1b1 !important
}
.h3Cls {
    background-color: #efefef !important
}
.h4Cls {
    background-color: #FFFFFF !important
}
.otherCls {
    background-color: #FFFFFF
}
.yellowCls{
    background-color: #FFFF00;
}
.blueCls{
    background-color: #9CC2E5;
}
.whiteCls{
    background-color: #FFFFFF;
}
.fr-box.fr-basic {
    z-index: 0;
}
.split-flex {
    display: flex;
    flex-direction: row;
    height: 100%;
}
.gutter.gutter-horizontal {
    cursor: ew-resize;
    background-color: black;
}
.split {
    overflow-y: auto;
    overflow-x: hidden;
}
.gutter {
    z-index: 1;
}