@font-face {
  font-family: 'ProximaNova-Regular';
  src: local('ProximaNova-Regular'), url('../font/ProximaNova-Regular.otf') format('truetype');
  }

    @font-face {
      font-family: 'Proxima Nova Bold';
      src: local('Proxima Nova Bold'), url('../font/Proxima Nova Bold.otf') format('truetype');
      }

    *{
      font-family: ProximaNova-Regular !important;
    }

